import { Utils } from '@sbs/uikit-constructor';
import { applyOperation, applyPatch, getValueByPointer } from 'fast-json-patch';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale/index.js';
import { filesize as fileSize } from 'filesize';
import unzip from 'lodash/unzip.js';
import zipObjectDeep from 'lodash/zipObjectDeep.js';
import isNumber from 'lodash/isNumber.js';
import { toRaw } from 'vue';

const SECOND_IN_MS = 1000;
const MINUTE_IN_SEC = 60;
const HOUR_IN_MIN = 60;
const DAY_IN_HOURS = 24;
const WEEK_IN_DAYS = 7;

const declensionMinutes = ['минуту', 'минуты', 'минут'];

const declensionHours = ['час', 'часа', 'часов'];

/**
 * Сравнивает 2 даты в timestamp и возращает объект
 * @param {number} timestamp1
 * @param {number} timestamp2
 * @returns object
 */
export const timeDiff = (timestamp1, timestamp2) => {
    const a = new Date(timestamp1).getTime();
    const b = new Date(timestamp2).getTime();
    const diff = {};

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / SECOND_IN_MS;
    diff.minutes = diff.seconds / MINUTE_IN_SEC;
    diff.hours = diff.minutes / HOUR_IN_MIN;
    diff.days = diff.hours / DAY_IN_HOURS;
    diff.weeks = diff.days / WEEK_IN_DAYS;

    return diff;
};

/**
 * Возвращает строку со статусом обновления
 * @param {number} timestamp
 * @returns string
 */
export const getChangesTime = timestamp => {
    const { seconds, minutes, hours } = timeDiff(timestamp, Date.now());

    if (seconds < MINUTE_IN_SEC) {
        return 'Обновлено только что';
    } else if (minutes < HOUR_IN_MIN) {
        // выводим количество минут
        const roundedMinutes = Math.floor(minutes);

        return `Обновлено ${roundedMinutes} ${Utils.Helpers.pluralize(
            roundedMinutes,
            declensionMinutes,
        )} назад`;
    } else if (hours < DAY_IN_HOURS) {
        // выводим часы
        const roundedHours = Math.floor(hours);

        return `Обновлено ${roundedHours} ${Utils.Helpers.pluralize(
            roundedHours,
            declensionHours,
        )} назад`;
    }

    const addedDate = new Date(timestamp);
    const addedDay = String(addedDate.getDate()).padStart(2, '0');
    const addedMonth = String(addedDate.getMonth()).padStart(2, '0');
    const addedYear = addedDate.getFullYear();
    const addedHours = String(addedDate.getHours()).padStart(2, '0');
    const addedMinutes = String(addedDate.getMinutes()).padStart(2, '0');

    return `Обновлено ${addedDay}.${addedMonth}.${addedYear} в ${addedHours}.${addedMinutes}`;
};

/**
 * Возвращает строку со статусом обновления для даты создания
 * @param {number} timestamp
 * @returns string
 */
export const getCreatedAtChangesTime = (
    timestamp,
    defaultText = 'Только что',
) => {
    const currentTime = Date.now();
    const { seconds, minutes, hours } = timeDiff(timestamp, currentTime);

    if (seconds < MINUTE_IN_SEC) {
        return defaultText;
    } else if (minutes < HOUR_IN_MIN) {
        // выводим количество минут
        const roundedMinutes = Math.floor(minutes);

        return `${roundedMinutes} ${Utils.Helpers.pluralize(
            roundedMinutes,
            declensionMinutes,
        )} назад`;
    } else if (hours < DAY_IN_HOURS) {
        // выводим часы
        const roundedHours = Math.floor(hours);

        return `${roundedHours} ${Utils.Helpers.pluralize(
            roundedHours,
            declensionHours,
        )} назад`;
    }

    return format(new Date(timestamp).getTime(), 'dd LLLL yyyy', {
        locale: ru,
    });
};

/**
 * Возвращает строку со статусом обновления для даты последнего обнвления
 * @param {number} timestamp
 * @returns string
 */
export const geUpdatedAtChangesTime = (
    timestamp,
    defaultText = 'Только что',
    formatDate = 'dd LLL yyyy, HH:mm',
) => {
    const currentTime = Date.now();
    const { seconds, minutes, hours } = timeDiff(timestamp, currentTime);

    if (seconds < MINUTE_IN_SEC) {
        return defaultText;
    } else if (minutes < HOUR_IN_MIN) {
        // выводим количество минут
        const roundedMinutes = Math.floor(minutes);

        return `${roundedMinutes} ${Utils.Helpers.pluralize(
            roundedMinutes,
            declensionMinutes,
        )} назад`;
    } else if (hours < DAY_IN_HOURS) {
        // выводим часы
        const roundedHours = Math.floor(hours);

        return `${roundedHours} ${Utils.Helpers.pluralize(
            roundedHours,
            declensionHours,
        )} назад`;
    }

    return format(new Date(timestamp).getTime(), formatDate, { locale: ru });
};

/**
 * @param path
 * @param value
 * @param operation
 * @return {{op: string, path, value}}
 */
export const createPatchOperation = (path, value, operation = 'replace') => ({
    op: operation,
    path,
    value,
});

/**
 * Принимает изменяемый объект, значение для изменения и путь к изменяемому полю. Возвращает новый измененный объект.
 * @param {object} object изменяемый объект
 * @param {any} value результирующее значение
 * @param {string} path путь к изменяемому полю объекта
 * @param {string} [operat]
 * @returns object результирующий объект
 */
export const getNewBlockInstance = (object, value, path, operat) => {
    const operation = createPatchOperation(path, value, operat);

    return applyOperation(object, operation, false, false).newDocument;
};

/**
 * @param {object} object
 * @param {Array<[string, *, string]>} patch
 * @return {*}
 */
export const getNewBlockInstancePatch = (object, patch = []) => {
    const operations = patch.map(([path, value, op]) => createPatchOperation(path, value, op));

    return applyPatch(object, operations, false, false).newDocument;
};

/**
 * Заменяет элемент в массиве при совпадении id и возвращает новый мвссив с замененым элементом
 * @param {object} itemForReplace блок для замены
 * @param {Array} array целевой массив
 * @returns Array результирующий массив
 */
export const replaceItemInArrayById = (itemForReplace, array) => array.map(item => (item.id === itemForReplace.id ? itemForReplace : item));

/**
 * Получает элемент структуры
 * @param {number} item id
 * @param {string} item type
 * @param {object} stucture
 * @returns object
 */
export const getStructureItem = (id, type, structure) => {
    if (!id || !type) return null;

    const modules = structure?.modules || [];

    if (type === 'module') return modules.find(module => module.id === id);
    else if (type === 'section') {
        return modules
            .map(item => item.sections)
            .flat()
            .find(section => section.id === id);
    }

    return modules
        .map(item => [
            ...(item.pages ?? []),
            ...item.sections.map(section => section.pages ?? []).flat(),
        ])
        .flat()
        .find(page => page.id === id);
};

/**
 * Получает имя элемента структуры
 * @param {object} item
 * @param {string} item type
 * @returns string
 */
export const getStructureItemName = (item, type) => {
    if (!item || !type) return '';

    return type === 'page' ? item.name : item.title;
};

/**
 * Возвращает строку типа "00:00"
 * @param {number} milliseconds вермя в милисикундах
 * @param {number} padStart
 * @returns string строка типа "00:00"
 */
export const convertTimeToMinutesAndSeconds = (
    milliseconds,
    { padStartMinutes = 2 } = {},
) => {
    let seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);

    seconds %= 60;

    return `${minutes.toString().padStart(padStartMinutes, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
};

/**
 * Принимает время в формате hh:mm:ss или mm:ss и возвращает кол-во секунд или минут
 * hh:mm:ss -> секунд
 * mm:ss -> секунд
 * hh:mm -> минут
 *
 *
 * @param {string} [timeString]  часы минуты и секунды
 * @returns {number} кол-во секунд минут
 */
export const getSecondsFromFormattedTimeString = (timeString = '') => {
    if (!timeString) return 0;

    return timeString
        .split(':')
        .reduce((acc, time) => MINUTE_IN_SEC * acc + Number(time));
};

export const getFileEnvUrl = url => {
    if (typeof url === 'string') {
        return process.env.NODE_ENV === 'production'
            ? url
            : url.replace(process.env.VUE_APP_BACKEND_API_URL, location.origin);
    }

    return '';
};

export const redirectToAuth = () => {
    const targetUrl =
        process.env.NODE_ENV === 'production'
            ? window.location.origin
            : process.env.VUE_APP_BACKEND_API_URL;
    const authUrl = new URL(targetUrl);

    authUrl.pathname = '/login/authorize';
    authUrl.searchParams.append('backTo', window.location.href);
    console.warn('redirecting to auth:', authUrl);
    location.assign(authUrl);
};

export const redirectToLogout = () => {
    const targetUrl =
        process.env.NODE_ENV === 'production'
            ? window.location.origin
            : process.env.VUE_APP_BACKEND_API_URL;

    location.assign(`${targetUrl}/logout`);
};

export const rgba2Hex = color => {
    if (color.substr(0, 1) === '#') return color;

    const strippedColor = color.replace(/\s+/g, '');
    const digits =
        /(.*?)rgb(a)??\((\d{1,3}),(\d{1,3}),(\d{1,3})(,([01]|1.0*|0??\.([0-9]{0,})))??\)/.exec(strippedColor);

    if (!digits) return '';

    const red = parseInt(digits[3], 10);
    const green = parseInt(digits[4], 10);
    const blue = parseInt(digits[5], 10);
    // eslint-disable-next-line no-bitwise, no-magic-numbers, no-mixed-operators
    const rgb = (blue | (green << 8) | (red << 16) | (1 << 24))
        .toString(16)
        .slice(1);

    return `#${rgb.toString(16)}`;
};

export const copyValueToClipboard = value => {
    const textArea = document.createElement('textarea');

    textArea.value = value;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
};

/**
 * returns text of html elements
 * @param {string} htmlElements string of html
 * @returns string text
 */
export const getHtmlText = htmlElements => {
    const wrapper = document.createElement('div');

    wrapper.innerHTML = htmlElements;

    return wrapper.innerText || '';
};

/**
 * returns text length of html elements
 * @param {string} html string of html
 * @param {object} [options]
 * @param {boolean} [options.withImage] для того чтоб картинки считались как 1 символ
 * @param {boolean} [options.countLatex] для того чтоб формулы считались как 1 символ
 * @returns number text length
 */
// eslint-disable-next-line complexity
export const getHtmlTextLength = (html, options) => {
    const text = getHtmlText(html);

    let countImage = 0;
    let countLatex = 0;

    if (html && (options?.withImage ?? true)) {
        countImage = html.match(/<img/gim)?.length ?? 0;
    }

    if (html && (options?.withFormula ?? true)) {
        countLatex = (html.match(/<span[^>]*data-latex[^>]*>/gim) || []).length;
    }

    return text.length || countImage || countLatex || 0;
};

export const downloadFile = file => {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = '';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

/**
 * Разбивает секунды на временные составляющие
 *
 * @param seconds
 * @returns {{hours: number, seconds: number, minutes: number}}
 */
export const getTimeEntitiesFromSeconds = seconds => ({
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds / 60) % 60),
    seconds: Math.round(seconds % 60),
});

/**
 * Отдает обрезанную строку без пробелов и энтеров
 *
 * @param {string}
 * @returns trimed string
 */
export const getTrimedText = string => string.replace(/\s+/g, '').trim();

export const delay = timeout => new Promise(resolve => {
    setTimeout(resolve, timeout);
});

/**
 * @param action
 * @param defaultReason
 * @returns {*&{reason: (*|string), value: (*|boolean)}}
 */
export const getAction = (
    action,
    defaultReason = 'У вас недостаточно прав',
) => ({
    ...action,
    value: action?.value ?? false,
    reason: action?.reason ?? defaultReason,
});

export const filesize = (size, options) => fileSize(size, {
    locale: 'ru',
    standard: 'jedec',
    base: 2,
    symbols: {
        B: 'Б',
        KB: 'Кб',
        MB: 'Мб',
        GB: 'Гб',
        TB: 'Тб',
        PB: 'Пб',
    },
    ...options,
});

/**
 * @param {File} file
 * @param {string} [accept]
 * @returns {boolean}
 */
export const verifyFileAccept = (file, accept) => {
    if (!accept) return true;

    const parts = accept.split(',').map(part => part.trim());
    const extensionParts = parts.filter(part => part.startsWith('.'));
    const mimeParts = parts.filter(part => !part.startsWith('.')).join(',');

    const mimeResult = mimeParts
        ? new RegExp(mimeParts.replace(/\*/g, '.*').replace(/,/g, '|')).test(file.type)
        : false;

    return (
        mimeResult || extensionParts.some(part => file.name.endsWith(part))
    );
};

/**
 * @param {string} link
 * @param {'audio'|'video'} type
 * @return {Promise<number>}
 */
export const getDurationMediaLink = async (link, type) => {
    let duration = 0;
    /**
     * @type {HTMLAudioElement|HTMLVideoElement|null}
     */
    let mediaElement = null;

    if (type === 'audio') {
        mediaElement = new Audio();
    }

    if (type === 'video') {
        mediaElement = document.createElement('video');
    }

    if (mediaElement) {
        duration = await new Promise(resolve => {
            mediaElement.preload = 'metadata';
            // mediaElement.crossOrigin = 'use-credentials';
            mediaElement.addEventListener('loadedmetadata', () => resolve(mediaElement.duration));
            mediaElement.addEventListener('error', () => resolve(0));
            setTimeout(() => resolve(0), 5000);
            mediaElement.src = link;
        });
    }

    return duration;
};

/**
 * @param {File} file
 * @return {Promise<number>}
 */
export const getDurationMedia = async file => {
    let duration = 0;
    const blobUrl = URL.createObjectURL(file);

    if (/^audio\//.test(file.type)) {
        duration = await getDurationMediaLink(blobUrl, 'audio');
    }

    if (/^video\//.test(file.type)) {
        duration = await getDurationMediaLink(blobUrl, 'video');
    }

    URL.revokeObjectURL(blobUrl);

    return duration;
};

/**
 * @param {*} value
 * @param {number} defaultValue
 * @return {number}
 */
export const getNumberOrElse = (value, defaultValue) => {
    if (Math.isNaN(value)) return defaultValue;

    return value;
};

/**
 * @param {Array<[string, *]>} entries
 * @return {object}
 */
export const zipEntriesObjectDeep = entries => {
    const [props, values] = unzip(entries);

    return toRaw(zipObjectDeep(props, values));
};

/**
 * @param {Record<string, *>} records
 * @return {object}
 */
export const zipRecordsObjectDeep = records => zipEntriesObjectDeep(toRaw(Object.entries(records)));

/**
 * @param {string} path
 * @returns {string}
 */
export const pathToDotPath = path => path
    .replace(/^\//, '')
    .replaceAll('/', '.')
    .replaceAll(/(?<=\d+)\.(?=\d+)/g, '][')
    .replaceAll(/(?<=\d)\.(?!\d)/g, '].')
    .replaceAll(/(?<!\d)\.(?=\d)/g, '[');

/**
 * @param {object} obj
 * @param {string} path
 * @return {*}
 */
export const getValueByPath = (obj, path) => getValueByPointer(obj, path);

/**
 * @param {boolean} condition
 * @param {string} message
 */
export const throwIf = (condition, message) => {
    if (condition) {
        throw new Error(message);
    }
};

/**
 * Получаем контент первого параграфа и удаляем его из разметки
 * @param string
 * @returns {{restHTML: string, firstParagraphContent: string}|{restHTML, firstParagraphContent: string}}
 */

export const getHTMLFirstParagraphContentFromString = string => {
    const result = {
        firstParagraphContent: '',
        restHTML: string,
    };

    if (!string) return result;

    const parser = new DOMParser();
    const markup = parser.parseFromString(string, 'text/html');
    const { firstChild } = markup.body;

    if (firstChild.tagName !== 'P') return result;

    result.firstParagraphContent = firstChild.innerHTML;

    markup.body.removeChild(firstChild);

    result.restHTML = markup.body.innerHTML;

    return result;
};

// https://github.com/youzan/vant/issues/3823
/**
 * @type {function(el: HTMLElement, root: HTMLElement|Window = window): HTMLElement|null}
 */
export const getOverflowParent = (() => {
    const canUseDom = Boolean(typeof window !== 'undefined' &&
            typeof document !== 'undefined' &&
            window.document &&
            window.document.createElement);

    const overflowReg = /scroll|auto|overlay|hidden/i;
    // eslint-disable-next-line no-undefined
    const defaultRoot = canUseDom ? window : undefined;

    const isElement = node => {
        const ELEMENT_NODE_TYPE = 1;

        return node.nodeType === ELEMENT_NODE_TYPE;
    };

    return (el, root = defaultRoot) => {
        let node = el;

        while (node && node !== root && isElement(node)) {
            const { overflowY } = window.getComputedStyle(node);

            if (overflowReg.test(overflowY)) {
                return node;
            }

            node = node.parentNode;
        }

        return root;
    };
})();

/**
 * Безопасное открытие нового окна браузера
 * @param attrs
 */
export const windowOpen = (...attrs) => {
    const instance = window.open(...attrs);

    instance.opener = null;
};

export const getUploadProgress = event => Math.round((event.loaded / event.total) * 100);

/**
 * Безопасное открытие нового окна браузера
 * @param params
 */
export const removeEmptyQueryFields = params => {
    const query = {};

    for (const key in params) {
        if (
            params[key] &&
            (isNumber(params[key]) || Object.keys(params[key]).length)
        ) {
            query[key] = params[key];
        }
    }

    return query;
};

/**
 * @param {*} value
 * @return {boolean}
 */
export const isNumeric = value => /^-?\d+$/.test(value);

/**
 * @param {*} str1
 * @param {*} str2
 * @param {boolean} [strict]
 * @return {boolean}
 */
export const strIncludes = (str1, str2, strict = false) => {
    const str1lower = String(str1).toLowerCase();
    const str2lower = String(str2).toLowerCase();

    if (strict) return str1lower.includes(str2lower);

    return str1lower.includes(str2lower) || str2lower.includes(str1lower);
};

/**
 * @param {HTMLCanvasElement} canvas
 * @param {{ ext: 'jpg'|'png', name: string }} options
 * @returns {Promise<File>}
 */
export const getCanvasImageFile = (canvas, options) => {
    const types = {
        jpg: 'image/jpeg',
        png: 'image/png',
    };

    const { ext, name } = options;

    const type = types[ext];

    return new Promise((resolve, reject) => {
        try {
            canvas.toBlob(blob => {
                try {
                    const file = new File([blob], name, {
                        type,
                    });

                    resolve(file);
                } catch (e) {
                    reject(e);
                }
            }, type);
        } catch (e) {
            reject(e);
        }
    });
};
