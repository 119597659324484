/* eslint-disable camelcase */

// TODO: Нужен рефактор на debounce отправки данных на сервер
// https://rtrack.ru/projects/vshsber/issues/477
import _debounce from 'lodash/debounce.js';
import keyBy from 'lodash/keyBy.js';
import castArray from 'lodash/castArray.js';
import { deepClone } from 'fast-json-patch';
import {
    addPageActivityQuestion,
    changePageActivityQuestion,
    restorePageActivityQuestion,
    removePageActivityQuestion,
    sortPageActivityQuestion,
    addPageActivityQuestionAnswer,
    removePageActivityQuestionAnswer,
    restorePageActivityQuestionAnswer,
    changePageActivityQuestionAnswer,
    sortPageActivityQuestionAnswer,
    copyPageActivityQuestion,
    getPageActivityQuestionImportExample,
    importPageActivityQuestions,
    exportPageActivityQuestions,
    exportPageActivityQuestionsWithAnswers,
    removePageActivityQuestionAnswers,
} from '@/api/activityQuestionApi.js';
import { downloadFile, getHtmlText, getNewBlockInstance, getUploadProgress } from '@/utils/utils.js';
import { createCancelHttp, isHttpCancel, showErrorSnackbar as showError } from '@/utils/http.js';
import { DEBOUNCE_TIME, DEBOUNCE_TIME_CHANGE_ANSWER, IMAGE_EXTENSION, IMAGE_TYPES } from '@/constants/index.js';
import {
    QUESTION_TYPES,
    MATCHING_QUESTION_COLUMN_TYPES,
    VISIBILITY_MIN_ANSWERS, MIN_ANSWER_LEVEL, DEFAULT_LIKERT_ANSWERS_LENGTH,
} from '@/components/Blocks/BlockTestOrQuiz/constants.js';
import { getDefaultQuestionOptions } from '@/components/Blocks/BlockTestOrQuiz/helpers.js';
import useSnackbar from '@/hooks/snackbars.js';
import { validateFile } from '@/utils/validators.js';
import { ACCEPT_FILE_IMPORT_TYPES } from '@editor-settings/views/TestOrQuizSettings/contants.js';
import { bytes } from '@/utils/helpers.js';

const { createSnackbar } = useSnackbar();

const debouncedChangeActivityAnswer = {};

const createDebouncedChangeActivityAnswer = () => _debounce(async ({ dispatch }, { activityId, questionId, answerId, answerData, cancelToken }) => {
    try {
        const resp = await changePageActivityQuestionAnswer(
            questionId,
            answerId,
            answerData,
            {
                cancelToken,
            },
        );

        dispatch('saveActivityQuestionAnswer', {
            activityId,
            questionId,
            answerId,
            answerData: {
                ...answerData,
                ...resp.data,
            },
        });

        dispatch('setEditabledAnswerId', null);

        dispatch('updateInitialStructure');
    } catch (e) {
        if (!isHttpCancel(e)) {
            dispatch('setEditabledAnswerId', null);
            console.error('[createDebouncedChangeActivityAnswer action] save question answer error', e);
        }
    }
}, DEBOUNCE_TIME_CHANGE_ANSWER);

export const getCountAnswersShouldAnswered = question => {
    if (question?.type === QUESTION_TYPES.GAPS_IN_TEXT) return question.answers?.length || 1;

    if (question?.type === QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY) {
        return question.answers?.map(answer => answer.children || []).flat().length || 1;
    }

    if (question?.type === QUESTION_TYPES.MATCHING) {
        const count = question.answers?.reduce((prev, curr) => {
            try {
                const answerData = JSON.parse(curr.data);

                if ('side' in answerData && answerData.side === 'right' && Array.isArray(answerData.correctAnswerId)) {
                    return prev + answerData.correctAnswerId.length;
                }
            } catch (e) {
                console.error('[getCountAnswersShouldAnswered]:', e);
            }

            return prev;
        }, 0);

        return count > 0 ? count : 1;
    }

    return question?.answers.filter(answer => answer.is_correct).length || 1;
};

/**
 * @param {object} newQuestion
 * @param {object} [prevQuestion]
 * @returns {*|(*&{question_data: string})}
 */
const getNormalizeQuestionData = (newQuestion, prevQuestion) => {
    const shouldNormalize = VISIBILITY_MIN_ANSWERS.includes(newQuestion.type);

    if (!shouldNormalize) return newQuestion;

    const newQuestionData = JSON.parse(newQuestion.question_data);

    const MAX_ANSWER_LEVEL = getCountAnswersShouldAnswered(newQuestion);

    if (prevQuestion && prevQuestion.type === newQuestion.type) {
        const prevQuestionData = JSON.parse(newQuestion.question_data);
        const prevCountAnswersShouldAnswered = getCountAnswersShouldAnswered(prevQuestion);

        if (prevQuestionData.minAnswersLevel === prevCountAnswersShouldAnswered) {
            newQuestionData.minAnswersLevel = MAX_ANSWER_LEVEL;
        }
    }

    const minAnswersLevel = newQuestionData.minAnswersLevel
        ? Math.max(MIN_ANSWER_LEVEL, Math.min(newQuestionData.minAnswersLevel, MAX_ANSWER_LEVEL))
        : MAX_ANSWER_LEVEL;

    return {
        ...newQuestion,
        question_data: JSON.stringify({
            ...newQuestionData,
            minAnswersLevel,
        }),
    };
};

/**
 * @type {import('vuex').ActionTree<any, any>}
 */
const activityQuestionActions = {
    pushActivityQuestion({ commit, getters }, { activityId, questionData }) {
        const currentActivity = getters.getActivity(activityId);
        const newActivity = {
            ...currentActivity,
            questions: [
                ...currentActivity.questions,
                questionData,
            ],
        };

        commit('CHANGE_ACTIVITY', newActivity);
    },
    replaceActivityQuestion({ commit, getters }, { activityId, questionId, questionData }) {
        const currentActivity = getters.getActivity(activityId);
        const newActivity = {
            ...currentActivity,
            questions: currentActivity.questions.map(item => (
                item.id === questionId ? questionData : item
            )),
        };

        commit('CHANGE_ACTIVITY', newActivity);
    },

    // eslint-disable-next-line max-lines-per-function, max-statements
    async addActivityQuestion({ dispatch, getters }, payload) {
        try {
            const { activityId, insertAfterQuestionId } = payload;
            const currentActivity = getters.getActivity(activityId);

            const newQuestion = {
                title: '',
                description: '',
                type: QUESTION_TYPES.SINGLE_CHOICE,
                question_data: JSON.stringify(getDefaultQuestionOptions({
                    blockType: currentActivity.data?.content?.type,
                    questionType: QUESTION_TYPES.SINGLE_CHOICE,
                })),
                answers: [
                    {
                        title: '',
                        is_correct: 1, // 1|0
                        order: 1,
                    },
                    {
                        title: '',
                        is_correct: 0,
                        order: 2,
                    },
                ],
            };

            const { data: responseData } = await addPageActivityQuestion(activityId, newQuestion);
            const createdQuestion = responseData.data;

            if (insertAfterQuestionId) {
                const questionsOrderIds = currentActivity.questions.map(q => q.id);
                const indexQuestionBefore = questionsOrderIds.indexOf(insertAfterQuestionId);
                const insertIndex = indexQuestionBefore + 1;
                const newQuestionsOrderIds = questionsOrderIds.toSpliced(insertIndex, 0, createdQuestion.id);

                await dispatch('sortActivityQuestion', {
                    activityId,
                    order: newQuestionsOrderIds,
                });

                createdQuestion.order = insertIndex;
            } else {
                await dispatch('pushActivityQuestion', {
                    activityId,
                    questionData: createdQuestion,
                });
            }

            return createdQuestion;
        } catch (error) {
            console.error('[addActivityQuestion action] add question error', error);

            return Promise.reject(error);
        }
    },

    resaveActivityQuestion({ dispatch, getters }, { activityId, questionId, prevQuestionData }) {
        const currentActivity = getters.getActivity(activityId);
        const questionData = currentActivity.questions.find(item => item.id === questionId);

        dispatch('changeActivityQuestion', {
            activityId,
            questionId,
            questionData,
            prevQuestionData,
        });
    },

    changeActivityQuestion({ dispatch }, { activityId, questionId, questionData, withAnswers, prevQuestionData }) {
        const normalizeQuestionData = getNormalizeQuestionData(questionData, prevQuestionData);

        dispatch('replaceActivityQuestion', {
            activityId,
            questionId,
            questionData: normalizeQuestionData,
        });

        dispatch('debouncedSaveActivityQuestion', {
            activityId,
            questionId,
            withAnswers,
            cancelToken: createCancelHttp('cancelTokenSaveActivityQuestion').token,
        });
    },

    async changeActivityQuestionImmediate({ dispatch }, { activityId, questionId, questionData, withAnswers }) {
        const normalizeQuestionData = getNormalizeQuestionData(questionData);

        await dispatch('replaceActivityQuestion', {
            activityId,
            questionId,
            questionData: normalizeQuestionData,
        });

        await dispatch('saveActivityQuestion', {
            activityId,
            questionId,
            withAnswers,
            cancelToken: createCancelHttp('cancelTokenSaveActivityQuestion').token,
        });
    },

    debouncedSaveActivityQuestion: _debounce(({ dispatch }, payload) => {
        dispatch('saveActivityQuestion', payload);
    }, DEBOUNCE_TIME),

    async saveActivityQuestion({ dispatch, getters }, { activityId, questionId, withAnswers, cancelToken }) {
        try {
            const currentActivity = getters.getActivity(activityId);
            const questionWithAnswers = currentActivity.questions.find(item => item.id === questionId);

            const { answers, ...questionWithoutAnswers } = questionWithAnswers;

            const questionData = withAnswers ? questionWithAnswers : questionWithoutAnswers;

            const { data } = await changePageActivityQuestion(activityId, questionId, questionData, { cancelToken });

            dispatch('replaceActivityQuestion', {
                activityId,
                questionId,
                questionData: data.data,
            });
            dispatch('updateInitialStructure');
        } catch (e) {
            if (isHttpCancel(e)) return;

            console.error('[saveActivityQuestion]', e);

            createSnackbar({
                type: 'error',
                message: 'Ошибка сохранения вопроса',
            });
        }
    },

    async copyActivityQuestion({ commit, dispatch, getters }, { activityId, questionId }) {
        try {
            const { data: respData } = await copyPageActivityQuestion(activityId, questionId);

            const newQuestion = respData.data;

            dispatch('refetchActivityAttachments', { blockId: activityId });

            const currentActivity = getters.getActivity(activityId);
            const questions = [...currentActivity.questions];
            const matchQuestionIdx = questions.findIndex(el => el.id === questionId);

            questions.splice(matchQuestionIdx + 1, 0, newQuestion);

            const newActivity = {
                ...currentActivity,
                questions,
            };

            commit('CHANGE_ACTIVITY', newActivity);
            dispatch('updateInitialStructure');

            return newQuestion;
        } catch (error) {
            console.error('[copyActivityQuestion action] copy question error', error);

            throw error;
        }
    },
    async removeActivityQuestion({ commit, getters, dispatch }, { activityId, questionId }) {
        try {
            await removePageActivityQuestion(activityId, questionId);

            const currentActivity = getters.getActivity(activityId);
            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.filter(item => item.id !== questionId),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('updateInitialStructure');

            return Promise.resolve();
        } catch (error) {
            console.error('[removeActivityQuestion action] remove question error', error);

            return Promise.reject(error);
        }
    },
    async restoreActivityQuestion({ commit, getters, dispatch }, { activityId, questionId }) {
        try {
            const { data } = await restorePageActivityQuestion(activityId, questionId);

            const currentActivity = getters.getActivity(activityId);
            const restoredQuestion = data.data;

            const newActivity = {
                ...currentActivity,
                questions: [restoredQuestion, ...currentActivity.questions],
            };

            newActivity.questions.sort((a, b) => a.order - b.order);

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('updateInitialStructure');

            return Promise.resolve(data.data);
        } catch (error) {
            console.error('[restoreActivityQuestion action] restore question error', error);

            return Promise.reject(error);
        }
    },
    async sortActivityQuestion({ commit, getters, dispatch }, { activityId, order }) {
        try {
            const { data } = await sortPageActivityQuestion(activityId, order);
            const currentActivity = getters.getActivity(activityId);
            const newActivity = {
                ...currentActivity,
                questions: data.data,
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('updateInitialStructure');

            return Promise.resolve();
        } catch (error) {
            console.error('[sortActivityQuestion action] sort question error', error);

            return Promise.reject(error);
        }
    },
    async addInitialChildrenAnswers({ dispatch }, { activityId, questionId, parentId, answerData = null }) {
        if (!parentId) return;

        const [{ data: childrenAnswer1 }, { data: childrenAnswer2 }] = await Promise.all([
            addPageActivityQuestionAnswer(questionId, {
                parent_id: parentId,
                is_correct: 1,
                data: answerData,
                order: 0,
            }),
            addPageActivityQuestionAnswer(questionId, {
                parent_id: parentId,
                is_correct: 0,
                data: answerData,
                order: 1,
            }),
        ]);

        dispatch('updateOrInsertAnswer', {
            activityId,
            questionId,
            parentId,
            newAnswer: [childrenAnswer1, childrenAnswer2],
        });
    },
    addInitalAnswersDitribCategory({ dispatch, getters }, { activityId, questionId }) {
        const question = getters.getQuestion(activityId, questionId);

        question?.answers?.forEach?.(answer => {
            dispatch('addInitialChildrenAnswers', {
                activityId,
                questionId,
                parentId: answer.id,
                answerData: JSON.stringify({ image: null }),
            });
        });
    },
    async addAnswerCategory({ dispatch, getters }, { activityId, questionId }) {
        const order = getters.getQuestionMaxOrderAnswer(activityId, questionId);

        const { data: newAnswer } = await addPageActivityQuestionAnswer(questionId, {
            is_correct: 1,
            data: JSON.stringify({ image: null }),
            order,
        });

        const [{ data: childrenAnswer1 }, { data: childrenAnswer2 }] = await Promise.all([
            addPageActivityQuestionAnswer(questionId, {
                parent_id: newAnswer.id,
                is_correct: 1,
                data: JSON.stringify({ image: null }),
                order: 0,
            }),
            addPageActivityQuestionAnswer(questionId, {
                parent_id: newAnswer.id,
                is_correct: 0,
                data: JSON.stringify({ image: null }),
                order: 1,
            }),
        ]);

        dispatch('updateOrInsertAnswer', {
            activityId,
            questionId,
            newAnswer: {
                ...newAnswer,
                children: [childrenAnswer1, childrenAnswer2],
            },
        });
    },
    updateOrInsertAnswer({ dispatch, commit, getters }, { activityId, questionId, parentId, newAnswer }) {
        const currentActivity = getters.getActivity(activityId);
        const prevQuestionData = getters.getQuestion(activityId, questionId);

        const newActivity = {
            ...currentActivity,
            questions: currentActivity.questions.map(item => {
                if (item.id === questionId) {
                    const answers = parentId
                        ? item.answers.map(answer => {
                            if (answer.id === parentId) {
                                return {
                                    ...answer,
                                    children: [
                                        ...(answer.children ?? []),
                                        ...castArray(newAnswer),
                                    ],
                                };
                            }

                            return answer;
                        })
                        : [
                            ...item.answers,
                            newAnswer,
                        ];

                    return {
                        ...item,
                        answers,
                    };

                }

                return item;
            }),
        };

        commit('CHANGE_ACTIVITY', newActivity);

        dispatch('resaveActivityQuestion', {
            activityId,
            questionId,
            prevQuestionData,
        });
    },
    // eslint-disable-next-line complexity
    async addActivityQuestionAnswer({ dispatch, getters }, { activityId, questionId, answerData = null }) {
        try {
            const order = getters.getQuestionMaxOrderAnswer(activityId, questionId, answerData?.parent_id);

            const getAnswerData = data => (data ? JSON.stringify(data) : null);

            const { parent_id, title, ...restAnswerData } = answerData || {};

            const { data } = await addPageActivityQuestionAnswer(questionId, {
                parent_id,
                is_correct: 0,
                data: getAnswerData(restAnswerData),
                order,
                title,
            });

            dispatch('updateOrInsertAnswer', {
                activityId,
                questionId,
                parentId: parent_id,
                newAnswer: data,
            });
        } catch (error) {
            console.error('[addActivityQuestionAnswer action] add question answer error', error);

            throw error;
        }
    },
    async switchAnswerWithChildCorrectAnswer({ dispatch, commit, getters }, { activityId, questionId, switchData }) {
        try {
            const currentActivity = getters.getActivity(activityId);

            if (switchData.new?.id) {
                await changePageActivityQuestionAnswer(
                    questionId,
                    switchData.new?.id,
                    {
                        ...switchData.new,
                        is_correct: 1,
                    },
                );
            }

            if (switchData.current?.id) {
                await changePageActivityQuestionAnswer(
                    questionId,
                    switchData.current?.id,
                    {
                        ...switchData.current,
                        is_correct: 0,
                    },
                );
            }

            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        return {
                            ...item,
                            answers: item.answers.map(answer => {
                                if (answer.children && answer.id === switchData.parent?.id) {
                                    return {
                                        ...answer,
                                        children: answer.children.map(child => {
                                            if (child.id === switchData.new?.id) {
                                                return {
                                                    ...child,
                                                    is_correct: 1,
                                                };
                                            }

                                            if (child.id === switchData.current?.id) {
                                                return {
                                                    ...child,
                                                    is_correct: 0,
                                                };
                                            }

                                            return child;
                                        }),
                                    };
                                }

                                return answer;
                            }),
                        };
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });
        } catch (error) {
            console.error('[switchAnswerWithChildCorrectAnswer action] switch answer withchild correct error', error);

            return Promise.reject(error);
        }
    },
    sendDebouncedActivityQuestionAnswer(ctx, payload) {
        const { activityId, questionId, answerId } = payload;
        const debounceKey = [activityId, questionId, answerId].join('_');

        if (!debouncedChangeActivityAnswer[debounceKey]) {
            debouncedChangeActivityAnswer[debounceKey] = createDebouncedChangeActivityAnswer();
        }

        debouncedChangeActivityAnswer[debounceKey](ctx, {
            ...payload,
            cancelToken: createCancelHttp(`saveActivityQuestionAnswer${answerId}`).token,
        });
    },
    saveActivityQuestionAnswer({ commit, getters }, { activityId, questionId, answerData }) {
        const currentActivity = getters.getActivity(activityId);

        const newActivity = {
            ...currentActivity,
            questions: currentActivity.questions.map(item => {
                if (item.id === questionId) {
                    return {
                        ...item,
                        answers: item.answers.map(answer => {
                            if (answerData.parent_id && answer.id === answerData.parent_id) {
                                return {
                                    ...answer,
                                    children: answer.children.map(child => {
                                        if (child.id === answerData.id) {
                                            return {
                                                ...child,
                                                ...answerData,
                                            };
                                        }

                                        return child;
                                    }),
                                };
                            }

                            if (answer.id === answerData.id) {
                                return {
                                    ...answer,
                                    ...answerData,
                                };
                            }

                            return answer;
                        }),
                    };
                }

                return item;
            }),
        };

        commit('CHANGE_ACTIVITY', newActivity);
    },
    changeActivityQuestionAnswer({ dispatch, getters }, { activityId, questionId, answerId, answerData }) {
        try {
            const prevQuestionData = deepClone(getters.getQuestion(activityId, questionId));

            dispatch('setEditabledAnswerId', questionId);

            dispatch('saveActivityQuestionAnswer', {
                activityId,
                questionId,
                answerId,
                answerData,
            });

            dispatch('sendDebouncedActivityQuestionAnswer', {
                activityId,
                questionId,
                answerId,
                answerData,
            });

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
                prevQuestionData,
            });

            return Promise.resolve();
        } catch (error) {
            console.error('[changeActivityQuestionAnswer action] add question answer error', error);

            return Promise.reject(error);
        }
    },
    async removeActivityQuestionAnswer({ dispatch, commit, getters }, { activityId, questionId, answerData }) {
        try {
            const currentActivity = getters.getActivity(activityId);
            const prevQuestionData = getters.getQuestion(activityId, questionId);

            await removePageActivityQuestionAnswer(questionId, answerData.id);

            if (answerData.parent?.id) {
                await changePageActivityQuestionAnswer(
                    questionId,
                    answerData.parent?.id,
                    answerData.parent,
                );
            }

            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.map(question => {
                    if (question.id === questionId) {
                        const answers = answerData.parent?.id
                            ? question.answers.map(answer => {
                                if (answer.children && answer.id === answerData.parent.id) {
                                    return {
                                        ...answerData.parent,
                                        children: answer.children.filter(child => child.id !== answerData.id),
                                    };
                                }

                                return answer;
                            })
                            : question.answers.filter(answer => answer.id !== answerData.id);

                        return {
                            ...question,
                            answers,
                        };
                    }

                    return question;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
                prevQuestionData,
            });

            return Promise.resolve();
        } catch (error) {
            console.error('[removeActivityQuestionAnswer action] remove question answer error', error);

            return Promise.reject(error);
        }
    },
    async restoreActivityQuestionAnswer({ commit, dispatch, getters }, { activityId, questionId, answerData, answerIndex }) {
        try {
            const currentActivity = getters.getActivity(activityId);
            const { data } = await restorePageActivityQuestionAnswer(questionId, answerData.id);

            if (answerData.parent?.id) {
                const currQuestion = currentActivity.questions.find(item => item.id === questionId);
                const currQuestionAnswer = currQuestion?.answers.find(item => item.id === answerData.parent?.id);

                if (currQuestionAnswer && currQuestionAnswer.children) {
                    currQuestionAnswer.children.splice(answerIndex || -1, 0, data);

                    dispatch('changeActivityQuestionAnswer', {
                        activityId,
                        questionId,
                        answerId: answerData.parent?.id,
                        answerData: {
                            ...currQuestionAnswer,
                            data: JSON.stringify({
                                ...JSON.parse(currQuestionAnswer.data),
                                sortData: currQuestionAnswer.children.map(item => item.id),
                            }),
                        },
                    });
                }
            } else {

                const newActivity = {
                    ...currentActivity,
                    questions: currentActivity.questions.map(item => {
                        if (item.id === questionId) {
                            item.answers.splice(answerIndex, 0, data);
                        }

                        return item;
                    }),
                };

                commit('CHANGE_ACTIVITY', newActivity);
            }

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });

            return Promise.resolve(data);
        } catch (error) {
            console.error('[restoreActivityQuestionAnswer action] restore question answer error', error);

            return Promise.reject(error);
        }
    },
    async sortActivityQuestionAnswers({ dispatch, commit, getters }, { activityId, questionId, order, parentId }) {
        try {
            const getOrderedAnswers = answers => {
                const answersByIds = keyBy(answers, 'id');

                return order
                    .map(id => answersByIds[id])
                    .filter(Boolean)
                    .map((child, ind) => ({
                        ...child,
                        order: ind,
                    }));
            };

            const currentActivity = getters.getActivity(activityId);
            const newActivity1 = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        if (parentId) {
                            const parentAnswer = item.answers.find(answerItem => answerItem.id === parentId);

                            if (!parentAnswer) return item;

                            return {
                                ...item,
                                answers: item.answers.map(answerItem => {
                                    if (answerItem.id !== parentId) return answerItem;

                                    return {
                                        ...answerItem,
                                        children: getOrderedAnswers(answerItem.children),
                                    };
                                }),
                            };
                        }

                        return {
                            ...item,
                            answers: getOrderedAnswers(item.answers),
                        };
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity1);

            await sortPageActivityQuestionAnswer(questionId, order);

            dispatch('updateInitialStructure');


            return Promise.resolve();
        } catch (error) {
            console.error('[sortActivityQuestionAnswers action] sort question answer error', error);

            return Promise.reject(error);
        }
    },

    /**
     * TODO: удалить, вроде нигде не используется
     */
    async removeQuestionAnswers({ dispatch, commit, getters }, { activityId, questionId }) {
        try {
            await removePageActivityQuestionAnswers(questionId);

            const currentActivity = getters.getActivity(activityId);
            const newActivity = {
                ...currentActivity,
                questions: currentActivity.questions.map(item => {
                    if (item.id === questionId) {
                        return {
                            ...item,
                            answers: [],
                        };
                    }

                    return item;
                }),
            };

            commit('CHANGE_ACTIVITY', newActivity);

            dispatch('resaveActivityQuestion', {
                activityId,
                questionId,
            });

            return Promise.resolve();
        } catch (error) {
            console.error('[removeQuestionAnswers action] removing answers', error);

            return Promise.reject(error);
        }
    },

    async downloadTemplate(_, { blockId }) {
        const { data } = await getPageActivityQuestionImportExample(blockId);

        downloadFile(data);
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {number} payload.blockId
     * @returns {Promise<void>}
     */
    async exportXlsx(_, payload) {
        const { data } = await exportPageActivityQuestions(payload.blockId);

        downloadFile(data);
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {number} payload.blockId
     * @param {number} payload.devCenterId
     * @returns {Promise<void>}
     */
    async exportTest(_, payload) {
        try {
            const { devCenterId, blockId } = payload;

            await exportPageActivityQuestionsWithAnswers(blockId);

            createSnackbar({
                title: 'Отчет добавлен в очередь на обработку',
                message: 'По завершению обработки, отчет можно скачать на странице отчетов',
                type: 'info',
                size: 'large',
                link: {
                    text: 'Перейти в раздел',
                    iconPrepend: true,
                    route: {
                        name: 'AdminReports',
                        params: {
                            developmentCenterId: devCenterId,
                        },
                    },
                },
                // TODO
                // snackbarOptions: {
                //     id: `report_user_answers_${blockId}`,
                // },
            });
        } catch (e) {
            console.error(e);

            createSnackbar({
                type: 'error',
                message: 'Ошибка формирования отчета',
            });
        }
    },
    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {string|number} payload.longreadId
     * @param {string|number} payload.blockId
     * @param {File} payload.file
     * @returns {Promise<void>}
     */
    async importExcel({ dispatch, commit }, { longreadId, blockId, file }) {
        try {
            commit('IMPORT_START', { blockId });

            await validateFile(file, {
                types: ACCEPT_FILE_IMPORT_TYPES,
            });

            const changeBlockInportProgress = progress => {
                commit('changeBlockInportProgress', {
                    blockId,
                    progress,
                });
            };

            const handleProgress = event => {
                const progress = getUploadProgress(event);

                changeBlockInportProgress(progress);
            };

            const { data } = await importPageActivityQuestions(blockId, file, handleProgress);

            changeBlockInportProgress(100);

            await dispatch('refetchActivity', {
                longreadId,
                blockId,
            });

            const { total_questions_count, imported_questions_count } = data;

            if (total_questions_count === imported_questions_count) {
                createSnackbar({
                    type: 'success',
                    message: 'Импорт успешно завершен',
                });
            } else {
                const snackbarTitle = imported_questions_count
                    ? `Успешно импортировано ${imported_questions_count} из ${total_questions_count}`
                    : `${imported_questions_count} из ${total_questions_count} импортировано`;
                const snackbarDescription = `${total_questions_count - imported_questions_count} вопроса импортировано с ошибкой. Проверьте правильность заполнения`;

                createSnackbar({
                    type: 'info',
                    title: snackbarTitle,
                    message: snackbarDescription,
                });
            }
        } catch (e) {
            showError(e);
        } finally {
            commit('IMPORT_FINISH', { blockId });
        }
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {string|number} payload.blockId
     * @param {string|number} payload.questionId
     * @param {string} payload.type
     */
    // eslint-disable-next-line complexity
    async changeQuestionType({ getters, dispatch }, payload) {
        const { blockId, questionId, type: newType } = payload;

        const block = getters.getActivity(blockId);
        const question = block?.questions?.find(q => q.id === questionId);

        if (!question) return;

        const newQuestion = {
            ...question,
            question_data: JSON.stringify(getDefaultQuestionOptions({
                blockType: block.data?.content?.type,
                questionType: newType,
            })),
            type: newType,
        };

        const getSimpleAnswer = (order, is_correct = 0, data = null) => ({
            title: '',
            is_correct,
            order,
            data,
        });

        // сбрасываем некоторые натройки блока когда информационный слайд
        if (newType === QUESTION_TYPES.INFORMATION) {
            const { questionsToSend, ...restOptions } = block.data.content.options;
            const newBlock = getNewBlockInstance(block, {
                ...restOptions,
                shakeQuestions: false,
            }, '/data/content/options');

            dispatch('changeBlock', newBlock);
        }

        if ([QUESTION_TYPES.MATCHING, QUESTION_TYPES.GAPS_IN_TEXT, QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY].includes(question.type)) {
            newQuestion.answers = [
                getSimpleAnswer(1, 1),
                getSimpleAnswer(2),
            ];
        }

        const dipatchChangeActivityQuestion = async questionData => {
            await dispatch('changeActivityQuestionImmediate', {
                activityId: blockId,
                questionId,
                questionData,
                withAnswers: true,
            });
        };

        if ([QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.QUIZ_CARDS].includes(newType)) {
            let isCorrectSettled = false;

            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: newQuestion.answers.map(item => {
                    const is_correct = Number(item.is_correct && !isCorrectSettled);

                    if (is_correct) {
                        isCorrectSettled = true;
                    }

                    let data = null;

                    if (newType === QUESTION_TYPES.QUIZ_CARDS) {
                        let parsedData = JSON.parse(item.data || null);

                        if (Array.isArray(parsedData)) {
                            parsedData = {};
                        }

                        data = JSON.stringify({
                            image: null,
                            ...parsedData,
                        });
                    }

                    return {
                        ...item,
                        title: newType === QUESTION_TYPES.QUIZ_CARDS ? getHtmlText(item.title) : item.title,
                        data,
                        is_correct,
                    };
                }),
            });
        } else if (newType === QUESTION_TYPES.LIKERT_SCALE) {
            const convertedLikertAnswers = newQuestion.answers.map(answer => ({
                ...answer,
                title: getHtmlText(answer?.title ?? ''),
                is_correct: 1,
            }));

            if (convertedLikertAnswers.length < DEFAULT_LIKERT_ANSWERS_LENGTH) {
                const diffLength = DEFAULT_LIKERT_ANSWERS_LENGTH - convertedLikertAnswers.length;
                const lastOrder = convertedLikertAnswers.length;

                for (let i = 0; i < diffLength; i++) {
                    convertedLikertAnswers.push(getSimpleAnswer(lastOrder + i));
                }
            }

            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: convertedLikertAnswers,
            });
        } else if (newType === QUESTION_TYPES.SORTING) {
            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: newQuestion.answers.map((item, idx) => ({
                    ...item,
                    order: idx + 1,
                })),
            });
        } else if (newType === QUESTION_TYPES.MATCHING) {
            const getAnswerSide = (order, side) => ({
                ...getSimpleAnswer(order),
                data: JSON.stringify({
                    correctAnswerId: null,
                    side,
                }),
            });

            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: [
                    getAnswerSide(1, MATCHING_QUESTION_COLUMN_TYPES.LEFT),
                    getAnswerSide(2, MATCHING_QUESTION_COLUMN_TYPES.LEFT),
                    getAnswerSide(3, MATCHING_QUESTION_COLUMN_TYPES.RIGHT),
                    getAnswerSide(4, MATCHING_QUESTION_COLUMN_TYPES.RIGHT),
                ],
            });
        } else if (newType === QUESTION_TYPES.GAPS_IN_TEXT) {
            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: [
                    getSimpleAnswer(1, 1),
                    getSimpleAnswer(2, 1),
                ],
            });
        } else if (newType === QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY) {
            await dipatchChangeActivityQuestion({
                ...newQuestion,
                answers: [
                    getSimpleAnswer(1, 0, JSON.stringify({ image: null })),
                    getSimpleAnswer(2, 0, JSON.stringify({ image: null })),
                ],
            });
            dispatch('addInitalAnswersDitribCategory', {
                activityId: blockId,
                questionId,
            });
        } else {
            await dipatchChangeActivityQuestion(newQuestion);
        }
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {File} payload.image
     * @param {string|number} payload.blockId
     * @param {string|number} payload.questionId
     * @param {string|number} payload.answerId
     * @param {string|number} [payload.parentId]
     * @returns {Promise<void>}
     */
    addImageAnswer({ dispatch, getters }, payload) {
        const { blockId, questionId, parentId, answerId, image } = payload;

        const prevAnswer = getters.getQuestionAnswer(blockId, questionId, answerId, parentId);
        const prevAnswerData = JSON.parse(prevAnswer.data || '{}');

        dispatch('uploadActivityAttachBase', {
            blockId,
            prevAttachId: prevAnswerData?.image?.attachment_id,
            uploadKey: answerId,
            file: image,
            validation: {
                types: IMAGE_TYPES,
                extensions: IMAGE_EXTENSION,
                maxSize: bytes('10mb'),
            },
            success: attach => {
                const answer = getters.getQuestionAnswer(blockId, questionId, answerId, parentId);

                if (!answer) return;

                const newAnswerData = {
                    ...JSON.parse(answer.data),
                    image: { attachment_id: attach.id },
                };

                const newAnswer = getNewBlockInstance(answer, JSON.stringify(newAnswerData), '/data');

                dispatch('changeActivityQuestionAnswer', {
                    activityId: blockId,
                    questionId,
                    answerId,
                    answerData: {
                        parent_id: parentId,
                        ...newAnswer,
                    },
                });
            },
        });
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {string|number} payload.blockId
     * @param {string|number} payload.questionId
     * @param {string|number} payload.answerId
     * @param {string|number} [payload.parentId]
     */
    async removeImageAnswer({ dispatch, getters }, payload) {
        try {
            const { blockId, questionId, answerId, parentId } = payload;
            const answer = getters.getQuestionAnswer(blockId, questionId, answerId, parentId);
            const answerData = JSON.parse(answer?.data || '{}');
            const attachId = answerData?.image?.attachment_id;

            if (!answer || !attachId) return;

            await dispatch('removeActivityAttachment', {
                blockId,
                attachId,
            });

            dispatch('changeActivityQuestionAnswer', {
                activityId: blockId,
                questionId,
                answerId,
                answerData: {
                    parent_id: parentId,
                    ...answer,
                    data: JSON.stringify({
                        ...answerData,
                        image: null,
                    }),
                },
            });

            createSnackbar({
                type: 'timer',
                message: 'Изображение удалено',
                actionButton: {
                    iconName: 'undo',
                    text: 'Отмена',
                    on: {
                        click: () => {
                            dispatch('restoreImageAnswer', {
                                blockId,
                                questionId,
                                answerId,
                                parentId,
                                attachId,
                            });
                        },
                    },
                },
            });

        } catch (e) {
            showError(e);
        }
    },

    /**
     * @param {import('vuex').ActionContext} store
     * @param {object} payload
     * @param {string|number} payload.blockId
     * @param {string|number} payload.questionId
     * @param {string|number} payload.answerId
     * @param {string|number} payload.attachId
     * @param {string|number} [payload.parentId]
     */
    async restoreImageAnswer({ dispatch, getters }, payload) {
        try {
            const { blockId, questionId, answerId, parentId, attachId } = payload;

            await dispatch('restoreActivityAttach', {
                blockId,
                attachId,
            });

            const currAnswer = getters.getQuestionAnswer(blockId, questionId, answerId, parentId);

            if (!currAnswer) return;

            dispatch('changeActivityQuestionAnswer', {
                activityId: blockId,
                questionId,
                answerId,
                answerData: {
                    parent_id: parentId,
                    ...currAnswer,
                    data: JSON.stringify({
                        ...JSON.parse(currAnswer.data || '{}'),
                        image: { attachment_id: attachId },
                    }),
                },
            });
        } catch (e) {
            showError(e);
        }
    },
};

export default activityQuestionActions;
